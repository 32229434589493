<template>
  <div class="column card mx-3" @click="toProductDetail(itemData)">
    <ion-label class="mx-2 my-2 name">{{ itemData.name }}</ion-label>

    <div class="line-grey"></div>
    <ion-row>
      <ion-col class="ion-no-padding" size="auto">
        <div class="product-image">
          <div class="image-wrapper ml-1">
            <treedots-image
              :key="key"
              :type="'PRODUCT'"
              :image="itemData.image"
              :cssClass="'image-wrapper'"
              :isHalal="itemData.halal"
              :isOos="isOos"
            ></treedots-image>
          </div>
          <div v-if="itemData && itemData.halal" class="halal-label">
            <p class="halal">{{ $t('halal') }}</p>
          </div>
        </div>
      </ion-col>
      <div class="ml-1 pr-2 pb-1 d-flex flex-column justify-space-between price-info">
        <div class="d-flex flex-column">
          <div class="stock mt-1">
            <ion-text v-if="!isAllOrderByWeight">{{ setWeightFn(itemData) }}</ion-text>
            <ion-text v-else>{{ $t('order_by_weight') }}</ion-text>
          </div>
          <div class="booking-id mt-2" v-if="showBookingId">
            <ion-label class="mt-2">{{ $t('booking') }} #{{ itemData.booking_order_id }}</ion-label>
          </div>
          <div class="booking-date mt-2" v-if="showBookingId">
            <ion-label class="mt-2">{{ $t('booking_date') }}: {{ bookingDateString }}</ion-label>
          </div>
          <div v-show="shouldShowPrice && !isAllOrderByWeight" class="price-range mt-1">
            {{ priceDiscountUomFn(itemData, userData?.currency_symbol) }}
          </div>
          <div v-show="shouldShowPrice" class="unit-price mt-1">
            {{ setTotalPricePerUomFn(itemData, userData?.currency_symbol, isCalculateByWeight, fromText) }}
          </div>
        </div>
        <ion-button
          v-if="isOos"
          class="mt-1 btn-outline"
          expand="block"
          fill="outline"
          @click="toProductDetail(itemData)"
          >{{ $t('view_similar_products') }}</ion-button
        >
      </div>
    </ion-row>
  </div>
  <div class="space"></div>
</template>
<script>
import TreedotsImage from '@/components/treedots-image';
import { priceDiscountUomFn, setTotalPricePerUomFn, setWeightFn } from '@/modules/b2b/services/libs/home';
import { QUOTED_PRICED_BY, USER_TYPE_ID } from '@/modules/shared/constants';
import dayjs from 'dayjs';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'NewProductItem',
  components: {
    TreedotsImage
  },
  emits: ['item-clicked'],
  props: {
    key: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => {}
    },
    user: {
      type: Object,
      default: () => {}
    },
    selectedCompany: {
      type: Object,
      default: () => {}
    },
    id: {
      type: String,
      default: ''
    }
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const storage = inject('$storage');
    const itemProductDetail = ref(null);
    const isOos = ref(false);
    const userData = ref(props.user);
    const fromText = ref(t('orderB2b.from'));
    const itemData = ref(props.item);
    const getUserData = async () => {
      userData.value = await storage.getUser();
    };

    const toProductDetail = (item) => {
      emit('item-clicked', item);
    };

    const checkIsOos = () => {
      isOos.value = itemProductDetail.value?.is_oos;
    };

    onMounted(() => {
      itemProductDetail.value = props.item.productAdditionalInfos;
      checkIsOos();
      getUserData();
    });

    // computed
    const isSalesAdmin = computed(() => {
      return (
        userData.value?.user_type_id === USER_TYPE_ID.ADMIN ||
        userData.value?.user_type_id === USER_TYPE_ID.SALE ||
        userData.value?.user_type_id === USER_TYPE_ID.SUPPLIER
      );
    });

    const showBookingId = computed(() => {
      return props.item?.booking_order_id && props.item?.booking_order_id > 0;
    });

    const bookingDateString = computed(() => {
      return dayjs(props.item?.booking_order_date).format('DD MMM YYYY');
    });

    const shouldShowPrice = computed(() => {
      return (
        !showBookingId.value &&
        (isSalesAdmin.value ? showQuotationLimitPrice.value || showPrice.value : showPrice.value)
      );
    });

    const showQuotationLimitPrice = computed(() => {
      return isSalesAdmin.value && (props.item?.max_price > 0 || props.item?.min_price > 0);
    });

    const showPrice = computed(() => {
      return (
        (props.item.quotation_statuses && props.item.quotation_statuses.split(',').includes('received')) ||
        props.item.hasDirectPrice
      );
    });

    const isAllOrderByWeight = computed(() => {
      return props.item.skusData?.every((item) => item.is_order_by_weight);
    });

    const isAllOrderCatchWeight = computed(() => {
      return props.item.skusData?.every((item) => item.is_catch_weight);
    });

    const isAllOrderPricedByWeight = computed(() => {
      return props.item.skusData?.every((item) => item.priced_by === QUOTED_PRICED_BY.WEIGHT);
    });

    const isCalculateByWeight = computed(() => {
      return isAllOrderByWeight.value || isAllOrderCatchWeight.value || isAllOrderPricedByWeight.value;
    });

    watch(
      () => props.item.productAdditionalInfos,
      (newVal) => {
        itemProductDetail.value = newVal;
        checkIsOos();
      }
    );
    return {
      itemProductDetail,
      isOos,
      fromText,
      getUserData,
      userData,
      itemData,
      setTotalPricePerUomFn,
      setWeightFn,
      priceDiscountUomFn,
      toProductDetail,
      checkIsOos,
      isSalesAdmin,
      showBookingId,
      bookingDateString,
      shouldShowPrice,
      showQuotationLimitPrice,
      showPrice,
      isAllOrderByWeight,
      isAllOrderCatchWeight,
      isAllOrderPricedByWeight,
      isCalculateByWeight,
      QUOTED_PRICED_BY
    };
  }
});
</script>

<style lang="scss" scoped>
.column {
  display: flex;
  flex-direction: column;
}
.card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  widows: 100%;
  height: fit-content;
  min-height: 156px;
}
.image-wrapper {
  height: 120px;
  width: 120px;
  border-radius: 0px 0px 0px 8px;
  overflow: hidden;
}
.line-grey {
  height: 1.2px;
  width: 100%;
  background-color: #e0e0e0;
}
.product-image::part(image) {
  position: relative;
  height: 120px;
  width: 120px;
  overflow: hidden;
  display: block;
  border-radius: 0px 0px 0px 8px;
}
ion-img {
  display: block;
  border-radius: 0px 0px 0px 8px;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.halal-label {
  position: absolute;
  margin-bottom: -0.5px;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 18px;
  background: #12b76a;
  border-radius: 0px 0px 0px 8px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  box-sizing: border-box;
}
.halal {
  margin-top: 2px;
}
.oos {
  margin-top: -1px;
}
.discount {
  font-weight: 700;
  font-size: 10px;
  margin-top: -0.1px;
}
.discount-label {
  position: absolute;
  height: 18px;
  min-width: 58px;
  max-width: 60px;
  left: 0px;
  right: 0px;
  top: 12px;
  gap: 10px;
  padding-top: 2px;
  margin-left: -8px;
  text-align: center;
  color: #ffffff;
  border-radius: 6px 6px 6px 0px;
  background: #d92d20;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}
.booking-id {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: #eb8c31;
  background: #fdf5ed;
  padding: 4px 12px;
  width: fit-content;
  border-radius: 10px;
}
.booking-date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #eb8c31;
  width: fit-content;
  border-radius: 10px;
}

.name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #212121;
}
.stock {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #757575;
}
.price-range {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.38px;
  color: #212121;
}
.unit-price {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #039855;
}

.space {
  height: 16px;
  width: 100%;
  background: transparent;
  background-color: transparent;
  color: transparent;
}

.btn-outline {
  height: 28px;
  font-size: 14px;
  width: 100%;
  text-transform: capitalize;
  --border-radius: 6px;
  --color: #00676a;
}

.price-info {
  width: calc(100% - 9rem);
}

@media only screen and (max-width: 375px) {
  .btn-outline {
    height: 20px;
    font-size: 8px;
    width: 100%;
    text-transform: capitalize;
    --border-radius: 4px;
    --color: #00676a;
  }
}
@media only screen and (max-width: 280px) {
  .price-info {
    width: 42vw;
  }
}
</style>
